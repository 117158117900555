



































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class BaseToolTip extends Vue {
	@Prop({ default: 'black' })
	color: string;

	//@Prop({ default: 'auto' }) // 200px;
	//width: string;

	@Prop({ default: 'auto' }) // 200px;
	width: string;

	displayToolTip = false;
	isScreenLarge = false;

	mounted() {
		this.isScreenLarge = window.innerWidth > 992;
		window.addEventListener('resize', this.handleWindowResize);
	}

	async beforeUnmount(): Promise<void> {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize() {
		// Update the computed property when the window is resized
		this.isScreenLarge = window.innerWidth > 992;
	}

	get style(): string {
		// Check if the screen width is larger than 992px
		return 'width:' + this.width;
	}
}
